const App = {
    run: function(inner, block) {
        // recommend to use inner element as selector for block to run js
        const sliderContainer = inner.querySelector('.menu-list')

		const slider = new tns({
			container: sliderContainer,
			items: 1,
			slideBy: 1,
			controls: false,
			nextButton: false,
			loop: false,
			nav: true,
			navPosition: "bottom",
			navAsThumbnails: true,
			responsive: {
				500: {
					items: 3,
					slideBy: 1,
                },
                768: {
					items: 1,
					slideBy: 1,
                },
                1025: {
					items: 3,
					center: false,
					fixedWidth: false
                }
            }
		})
    },
    initializeBlock: function(block) {
        let wrapper = block.querySelector('.menu-slider-wrapper')
        this.run(wrapper, block)
    },
    initializeBlockEditor: function(block) {
        // gutenberg fire the block
        App.initializeBlock(block[0])
    },
}

window.addEventListener('DOMContentLoaded', () => {
	const blocks = document.querySelectorAll('.elmntl-menu-slider')

	blocks.forEach(block => {
		App.initializeBlock(block)
	})
})

if (window.acf) {
    window.acf.addAction(
        'render_block_preview/type=menu-slider',
        App.initializeBlockEditor
    )
}